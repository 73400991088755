const Config = require('../../../config/default.env').default;

const META = Object.freeze({
  // <=== Homepage ===>
  WP: {
    TITLE:
      'BeautyJournal - Highlight Beauty hingga Lifestyle | Trend, Tips & Review',
    DESCRIPTION:
      'BeautyJournal adalah media informasi digital terkini soal beauty, lifestyle, pop culture, dan gaya hidup untuk anak muda perempuan',
    URL: Config.BJ_APPS_URL,
  },

  // <=== What's New ===>
  WHATS_NEW: {
    TITLE: "BeautyJournal: What's New | Semua Informasi Penting & Terbaru",
    DESCRIPTION:
      'Temukan berbagai berita terbaru dan wawancara seru seputar beauty, lifestyle, fashion, f&b, konser musik, dan berbagai event seru!',
    URL: `${Config.BJ_APPS_URL}/whats-new`,
    AMP_URL: `${Config.BJ_APPS_URL}/whats-new/amp/`,
  },
  WHATS_NEW_NEWS_SCOOP: {
    TITLE:
      'BeautyJournal: News Scoop | Berita Lifestyle & Beauty Paling Update',
    DESCRIPTION:
      'Segala informasi terbaru mulai dari rilis produk hingga berita terbaru dari dunia beauty, hiburan, seni, fashion, f&b, juga gadget',
    URL: `${Config.BJ_APPS_URL}/whats-new/news-scoop`,
  },
  WHATS_NEW_NOW_TRENDING: {
    TITLE: 'BeautyJournal: Now Trending | Semua yang Viral Ada di Sini',
    DESCRIPTION:
      'Semua informasi seputar tren makeup, tren fashion, produk viral, tempat nongkrong yang baru buka, hingga istilah gaul ada di sini!',
    URL: `${Config.BJ_APPS_URL}/whats-new/now-trending`,
  },
  WHATS_NEW_EVENT: {
    TITLE: 'BeautyJournal: Event | Temukan Acara-acara Seru di Sini',
    DESCRIPTION:
      'Dapatkan berbagai informasi soal beauty event, konser musik, fan meeting, pameran seni, sampai festival musik & kesenian yang seru',
    URL: `${Config.BJ_APPS_URL}/whats-new/event`,
  },
  WHATS_NEW_WHO_TO_WATCH: {
    TITLE:
      'BeautyJournal: Who To Watch | Kenalan dengan Profil Muda & Kekinian',
    DESCRIPTION:
      'Baca wawancara eksklusif seru dan inspiratif dengan para artis, influencer, content creator, seniman, hingga brand founder ternama',
    URL: `${Config.BJ_APPS_URL}/whats-new/who-to-watch`,
  },
  WHATS_NEW_ASK_THE_EXPERTS: {
    TITLE:
      'BeautyJournal: Ask The Experts | Tips Langsung dari Narasumber Ahli',
    DESCRIPTION:
      'Cari tahu lebih dalam tentang masalah dan kesehatan kulit, tips makeup & kecantikan, hingga kesehatan lewat penjelasan para expert',
    URL: `${Config.BJ_APPS_URL}/whats-new/ask-the-experts`,
  },

  // <=== What's Good ===>
  WHATS_GOOD: {
    TITLE: "BeautyJournal: What's Good | Semua Informasi yang Wajib Kamu Tahu",
    DESCRIPTION:
      'Cari review produk, rekomendasi produk & tempat nongkrong, serta informasi soal beauty, fashion, relationship hingga mental health',
    URL: `${Config.BJ_APPS_URL}/whats-good`,
    AMP_URL: `${Config.BJ_APPS_URL}/whats-good/amp/`,
  },
  WHATS_GOOD_EDITORS_REVIEW: {
    TITLE:
      "BeautyJournal: Editor's Review | Temukan Berbagai Review Terbaru & Populer",
    DESCRIPTION:
      'Baca review terpercaya, mulai dari review produk kecantikan populer, beauty treatment, buku, restoran, kafe, hingga hotel di sini!',
    URL: `${Config.BJ_APPS_URL}/whats-good/editors-review`,
  },
  WHATS_GOOD_GUIDES: {
    TITLE:
      'BeautyJournal: Guides | Panduan Terpercaya untuk Beauty & Lifestyle',
    DESCRIPTION:
      'Cari tahu lebih dalam mengenai istilah populer, permasalahan kulit, kandungan skincare, mental health, dan informasi penting lainnya di sini.',
    URL: `${Config.BJ_APPS_URL}/whats-good/guides`,
  },
  WHATS_GOOD_HOT_INSPO: {
    TITLE:
      'BeautyJournal: Hot Inspo | Semua Hal Inspiratif dari Para Selebritas',
    DESCRIPTION:
      'Temukan inspirasi dari penampilan makeup, nail art, gaya rambut, hingga fashion dari para selebritas tanah air hingga internasional.',
    URL: `${Config.BJ_APPS_URL}/whats-good/hot-inspo`,
  },
  WHATS_GOOD_TOP_PICKS: {
    TITLE:
      'BeautyJournal: Top Picks | Rekomendasi Belanja Sesuai Budget & Tren',
    DESCRIPTION:
      'Temukan berbagai rekomendasi produk beauty, fashion, treatment kecantikan, buku, destinasi wisata, kafe, hingga hotel yang telah dikurasi.',
    URL: `${Config.BJ_APPS_URL}/whats-good/top-picks`,
  },
  WHATS_GOOD_THE_A_LIST: {
    TITLE: 'BeautyJournal: The A List | Hal-Hal Favorit Para Seleb',
    DESCRIPTION:
      'Cari tahu di sini, apa saja produk beauty & fashion, buku, lagu, hingga tontonan seru yang menjadi favorit para selebritas ternama',
    URL: `${Config.BJ_APPS_URL}/whats-good/the-a-list`,
  },

  // <=== What's Fun ===>
  WHATS_FUN: {
    TITLE:
      "BeautyJournal: What's Fun | Bacaan Ringan dan Seru Saat Leyeh-Leyeh",
    DESCRIPTION:
      'Temukan inspirasi nail art, serba serbi zodiak, meme lucu, quotes lucu, quotes inspiratif, hingga quiz yang menarik untuk diikuti.',
    URL: `${Config.BJ_APPS_URL}/whats-fun`,
    AMP_URL: `${Config.BJ_APPS_URL}/whats-fun/amp/`,
  },
  WHATS_FUN_QUIZZ_POLL: {
    TITLE: 'BeautyJournal: Quizz Poll | Main Kuis Lucu-Lucuan di Saat Santai',
    DESCRIPTION:
      'Jangan ketinggalan mengikuti berbagai kuis kecantikan hingga lifestyle yang seru, serta kuis berhadiah menarik dari BeautyJournal!',
    URL: `${Config.BJ_APPS_URL}/whats-fun/quizzes-poll`,
  },
  WHATS_FUN_URL_FINDS: {
    TITLE: 'BeautyJournal: URL FINDS | Kumpulan Meme dan Quotes Viral',
    DESCRIPTION:
      'Tonton kumpulan konten ringan, mulai dari meme lucu hingga quotes viral yang shareable. Paling pas buat infinity scroll sebelum tidur!',
    URL: `${Config.BJ_APPS_URL}/whats-fun/url-finds`,
  },

  // <=== Beauty A-Z ===>
  BEAUTY_AZ: {
    TITLE: 'BeautyJournal: Beauty A Z | Ensiklopedia Beauty & Lifestyle',
    DESCRIPTION:
      'Cari tahu arti dari berbagai istilah kecantikan, kandungan skincare, hingga kumpulan video menarik dan seru dari para selebritas populer.',
    URL: `${Config.BJ_APPS_URL}/beauty-az`,
    AMP_URL: `${Config.BJ_APPS_URL}/beauty-az/amp/`,
  },

  // <=== Glossary ===>
  GLOSSARY: {
    TITLE: 'BeautyJournal: Glossary | Temukan Istilah dari Dunia Kecantikan',
    DESCRIPTION:
      'Cari tahu berbagai istilah kecantikan populer dan terbaru di sini. Mulai dari istilah makeup, skincare, hingga kandungan skincare.',
    URL: `${Config.BJ_APPS_URL}/glossary`,
  },

  // <=== Beauty Shorts ===>
  BEAUTY_SHORTS: {
    TITLE: 'Beauty Journal: Beauty Shorts | Tonton Video Para Seleb di Sini',
    DESCRIPTION:
      'Tonton seputar tips makeup, skincare, hingga tips fashion dari selebritas ternama. Cari tahu juga produk favorit & rekomendasi tontonan seru dari mereka.',
    URL: `${Config.BJ_APPS_URL}/beauty-short`,
    AMP_URL: `${Config.BJ_APPS_URL}/beauty-short/amp/`,
  },

  // <=== About Us ===>
  ABOUT_US: {
    TITLE:
      'Beauty Journal: About Us |  Highlight Beauty hingga Lifestyle | Trend, Tips & Review',
    DESCRIPTION:
      'Beauty Journal adalah media anak muda yang menyajikan konten-konten kecantikan dan gaya hidup yang informatif, terpercaya, dan relevan.',
    URL: `${Config.BJ_APPS_URL}/about-us`,
  },

  // <=== Contact Us ===>
  CONTACT_US: {
    TITLE:
      'Beauty Journal: Contact Us |  Highlight Beauty hingga Lifestyle | Trend, Tips & Review',
    DESCRIPTION:
      'Contact Beauty Journal for media & partnership, sales & advertising inquiries',
    URL: `${Config.BJ_APPS_URL}/contact-us`,
  },
});

const SOCIAL_MEDIA = Object.freeze({
  // <=== Twitter ===>
  TWITTER_BEAUTY_JOURNAL: 'https://twitter.com/beautyjournalid',

  // <=== Facebook ===>
  FACEBOOK_BEAUTY_JOURNAL: 'https://www.facebook.com/beautyjournalid',

  // <=== Facebook ===>
  INSTAGRAM_BEAUTY_JOURNAL: 'https://www.instagram.com/beautyjournal/',

  // <=== YouTube ===>
  YOUTUBE_BEAUTY_JOURNAL:
    'https://www.youtube.com/channel/UC7mQM7kjGT1Q0-5j1sv4VMg',

  // <=== Line ===>
  LINE_BEAUTY_JOURNAL: 'https://line.me/ti/p/%40lcu1578a',
});

const CONSTANT = Object.freeze({
  META,
  SOCIAL_MEDIA,
});

export default CONSTANT;
