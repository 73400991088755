import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_POSTS_BJ,
  API_AGENDA_BJ,
  API_TRENDING_TAG_BJ,
  API_BANNER_BJ,
  API_GLOSSARY_BJ,
} from '~global-api/bj.api';
import { API_PRODUCT_SOCO } from '~global-api/soco.api';
import {
  listAlphabet,
  getDateToDisplay,
  mapPostsWithDateDisplay,
} from '@web-utils';

import { isWeb } from '~global-screens';
const Config = require('../../config/default.env').default;

export const getHighlightHome = createAsyncThunk(
  'section/getHighlightHome',
  async () => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: '/highlight/home',
      payload: {
        params: {
          fields:
            '_id title slug owner summary category attachments post_type published_at created_at subcategory total_views total_comments updated_at is_toggled_update_timestamp',
        },
      },
    });

    return {
      data: mapPostsWithDateDisplay(response.data?.data),
    };
  },
);

export const getTrending = createAsyncThunk('section/getTrending', async () => {
  const limit = isWeb ? 12 : 6;
  const response = await API_POSTS_BJ({
    method: 'get',
    url: '/trending',
    payload: {
      params: {
        filter: {
          post_type: { $in: ['article', 'pid_quiz'] },
        },
        fields:
          '_id attachments category subcategory created_at owner post_type slug tags title published_at total_views total_comments updated_at is_toggled_update_timestamp',
        limit: limit,
      },
    },
  });

  return {
    data: mapPostsWithDateDisplay(response.data?.data),
  };
});

export const getBeautyAgenda = createAsyncThunk(
  'section/getBeautyAgenda',
  async () => {
    const response = await API_AGENDA_BJ();
    return response.data;
  },
);

export const getBeautyShort = createAsyncThunk(
  'section/getBeautyShort',
  async (skipValue) => {
    const limit = isWeb ? 12 : 6;
    const skipCount = skipValue ?? 0;
    const response = await API_POSTS_BJ({
      method: 'get',
      payload: {
        params: {
          filter: {
            post_type: 'bjtv',
            status: 'publish',
          },
          limit: limit,
          sort: '-published_at',
          skip: 0 + skipCount,
        },
      },
    });
    return response.data;
  },
);

export const getBeautyShortCount = createAsyncThunk(
  'section/getBeautyShortCount',
  async () => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: '/count',
      payload: {
        params: {
          filter: {
            post_type: 'bjtv',
            status: 'publish',
          },
        },
      },
    });
    return response.data;
  },
);

export const getPostData = createAsyncThunk(
  'section/getPostData',
  async (payload) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: `/${payload.slug}`,
      payload: {
        params: {
          section: 'bjtvDetail',
        },
      },
    });

    return {
      data: {
        ...response.data,
        date_to_display: getDateToDisplay(response.data),
      },
      type: payload.type,
      id: payload.id,
    };
  },
);

export const getTheHighlight = createAsyncThunk(
  'section/getTheHighlight',
  async () => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: '/highlight/the-highlights',
      payload: {
        params: {
          fields:
            '_id title slug owner summary category attachments post_type subcategory total_views total_comments published_at created_at updated_at is_toggled_update_timestamp',
        },
      },
    });

    return {
      data: mapPostsWithDateDisplay(response.data?.data),
    };
  },
);

export const getSectionData = createAsyncThunk(
  'section/getSectionData',
  async (payload) => {
    const limit = payload.limit ?? 7;
    const sort = payload.sort ?? '-published_at';
    const slug = payload.slug ?? payload.type;

    const threeMonths = new Date();
    threeMonths.setMonth(threeMonths.getMonth() - 3);

    const filter = {
      post_type: 'article',
      'subcategory.slug': slug,
    };

    if (payload.slug === 'url-finds' && payload.type === 'trending-finds') {
      filter.published_at = { $gte: threeMonths.toISOString() };
    }

    const response = await API_POSTS_BJ({
      method: 'get',
      payload: {
        params: {
          fields:
            '_id title slug owner summary category attachments post_type  created_at subcategory total_views updated_at published_at is_toggled_update_timestamp',
          filter: filter,
          sort: sort,
          limit: limit,
          skip: 0,
        },
      },
    });
    return {
      data: {
        data: mapPostsWithDateDisplay(response.data?.data),
      },
      type: payload.type,
    };
  },
);

export const getInfiniteBottomList = createAsyncThunk(
  'section/getInfiniteBottomList',
  async (lastInfiniteListPostSortId) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: '/random',
      payload: {
        params: {
          fields:
            '_id title slug owner summary category attachments post_type  created_at subcategory total_views updated_at is_toggled_update_timestamp',
          filter: {
            post_type: 'article',
          },
          sort: '-published_at',
          limit: isWeb ? 15 : 10,
          skip: lastInfiniteListPostSortId,
        },
      },
    });

    return {
      data: {
        ...response.data?.data,
        posts: mapPostsWithDateDisplay(response.data?.data?.posts),
      },
    };
  },
);

export const getInfiniteBottomListCount = createAsyncThunk(
  'section/getInfiniteBottomListCount',
  async () => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: '/count',
      payload: {
        params: {
          filter: {
            post_type: 'article',
          },
        },
      },
    });
    return response.data.count;
  },
);

export const getHotPickTags = createAsyncThunk(
  'section/getHotPickTags',
  async (payload) => {
    const response = await API_TRENDING_TAG_BJ({
      params: {
        filter: {
          'highlight.active': true,
          location: payload.type,
          'highlight.position': {
            $lte: 5,
          },
        },
        sort: 'highlight.position',
        limit: 5,
      },
    });
    return {
      data: response.data,
      type: payload.type,
    };
  },
);

export const getGlossary = createAsyncThunk('section/getGlossary', async () => {
  const limit = isWeb ? 12 : 6;
  const response = await API_POSTS_BJ({
    method: 'get',
    url: '/random',
    payload: {
      params: {
        filter: {
          post_type: 'bjglossary',
          'owner.user_type': { $exists: true },
        },
        sort: '-published_at',
        limit: limit,
        skip: 0,
      },
    },
  });
  return {
    data: {
      ...response.data?.data,
      posts: mapPostsWithDateDisplay(response.data?.data?.posts),
    },
  };
});

export const getHotPickData = createAsyncThunk(
  'section/getHotPickData',
  async (payload) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      payload: {
        params: {
          filter: {
            post_type: 'article',
            status: 'publish',
            'tags.slug': payload.keyword,
            'owner.user_type': { $nin: ['community'] },
          },
          skip: 0,
          limit: 7,
          sort: '-published_at',
        },
      },
    });

    return {
      data: mapPostsWithDateDisplay(response.data?.data),
    };
  },
);

export const getPinnedHighlight = createAsyncThunk(
  'section/getPinnedHighlight',
  async (location) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: `/highlight/${location}`,
      payload: {
        params: {
          fields:
            'id title slug owner summary category attachments post_type  created_at subcategory total_views total_comments updated_at, published_at is_toggled_update_timestamp',
        },
      },
    });

    return {
      data: {
        data: mapPostsWithDateDisplay(response.data?.data),
      },
      type: location,
    };
  },
);

export const getNowTrendingData = createAsyncThunk(
  'section/getNowTrendingData',
  async (payload) => {
    const limit = payload.limit ?? 5;
    const response = await API_POSTS_BJ({
      method: 'get',
      payload: {
        params: {
          filter: {
            post_type: 'article',
            status: 'publish',
            'owner.user_type': { $nin: ['community'] },
            'tags.slug': payload.keyword,
          },
          sort: '-published_at',
          skip: 0,
          limit: limit,
        },
      },
    });

    return {
      data: {
        data: mapPostsWithDateDisplay(response.data?.data),
      },
      type: payload.type,
    };
  },
);

export const getQuizListData = createAsyncThunk(
  'section/getQuizListData',
  async (payload) => {
    const url = payload.type === 'random' ? '/random' : '';
    const limit = payload.limit ?? 6;

    const response = await API_POSTS_BJ({
      method: 'get',
      url,
      payload: {
        params: {
          filter: { post_type: { $in: ['pid_quiz', 'polling'] } },
          fields:
            '_id title slug owner summary category attachments post_type published_at created_at subcategory total_views',
          sort: '-published_at',
          limit: limit,
          skip: 0,
        },
      },
    });
    return {
      data: {
        data: mapPostsWithDateDisplay(response.data?.data),
      },
      type: payload.type,
    };
  },
);

export const getTrendingReview = createAsyncThunk(
  'section/getTrendingReview',
  async (params) => {
    const response = await API_PRODUCT_SOCO(
      {
        params: {
          filter: {
            get_reviews: true,
          },
          section: 'haveYouTriedProducts',
          fields:
            'active brand default_category discount_type id images la_ps_product_id_product max_price min_price name has_bookmarked price price_after_discount review_stats total_reviews parent_category is_reviewed',
          limit: 10,
          skip: 0,
          ...(params?.sort && {
            sort: params.sort,
          }),
        },
      },
      '/featured/have-you-tried',
    );
    return response.data;
  },
);

export const getGlossaryTrending = createAsyncThunk(
  'section/getGlossaryTrending',
  async () => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: '/highlight/glossary',
      payload: {
        params: {
          fields: 'title published_at owner highlight slug',
        },
      },
    });
    return mapPostsWithDateDisplay(response.data?.data);
  },
);

export const getFreshPick = createAsyncThunk(
  'section/getFreshPick',
  async () => {
    const response = await API_POSTS_BJ({
      method: 'get',
      payload: {
        params: {
          filter: { post_type: 'bjglossary', status: 'publish' },
          sort: '-published_at',
          limit: 5,
        },
      },
    });
    return {
      data: mapPostsWithDateDisplay(response.data?.data),
    };
  },
);

export const getWordOfTheDay = createAsyncThunk(
  'section/getWordOfTheDay',
  async (payload) => {
    const limit = payload.limit ?? 1;
    const response = await API_POSTS_BJ({
      method: 'get',
      url: '/random',
      payload: {
        params: {
          filter: {
            post_type: 'bjglossary',
            'owner.user_type': { $exists: true },
          },
          sort: '-total_views',
          limit: limit,
          skip: 0,
        },
      },
    });
    return {
      data: {
        lastResultSortId: response.data?.lastResultSortId,
        posts: mapPostsWithDateDisplay(response.data?.data?.posts),
      },
    };
  },
);

export const getBeautyBottomList = createAsyncThunk(
  'section/getBeautyBottomList',
  async (lastInfiniteListPostSortId) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: '/random',
      payload: {
        params: {
          fields:
            '_id title slug owner summary category attachments post_type published_at created_at subcategory total_views updated_at, is_toggled_update_timestamp',
          filter: {
            post_type: 'article',
            status: 'publish',
            'tags.slug': 'beauty',
            'owner.user_type': { $nin: ['community'] },
          },
          sort: '-published_at',
          limit: isWeb ? 15 : 10,
          skip: lastInfiniteListPostSortId,
        },
      },
    });
    return {
      data: {
        lastResultSortId: response.data?.lastResultSortId,
        posts: mapPostsWithDateDisplay(response.data?.data?.posts),
      },
    };
  },
);

export const getGlossaryBanner = createAsyncThunk(
  'section/getGlossaryBanner',
  async () => {
    const response = await API_BANNER_BJ({
      params: {
        filter: {
          location: 'glossary',
        },
      },
    });
    return response.data;
  },
);

export const getGlossaryList = createAsyncThunk(
  'section/getGlossaryList',
  async (payload) => {
    const letters = payload.letters ?? listAlphabet;
    const limit = payload.limit ?? 6;
    const skipCount = payload.skipValue ?? 0;
    const filter = encodeURIComponent(
      JSON.stringify({
        letters: letters === '#' ? '#' : letters,
        status: 'publish',
      }),
    );
    const filterCount = encodeURIComponent(
      JSON.stringify({
        post_type: 'bjglossary',
        $or: [{ title: { $regex: `^${payload.letters}`, $options: 'i' } }],
        'owner.user_type': { $exists: true },
      }),
    );

    const hashtagFilterCount = encodeURIComponent(
      JSON.stringify({
        post_type: 'bjglossary',
        $or: [{ title: { $regex: '^[^a-zA-Z]', $options: 'i' } }],
        'owner.user_type': { $exists: true },
      }),
    );

    const url = `${Config.MS_BJ_PUBLIC_API_URL}/glossary?filter=${filter}&limit=${limit}&sort=slug&skip=${skipCount}`;
    const urlCount = `${Config.MS_BJ_PUBLIC_API_URL}/posts/count?filter=${
      letters === '#' ? hashtagFilterCount : filterCount
    }`;

    const response = await API_GLOSSARY_BJ(url);

    let countResponse;
    if (payload.type === 'count') {
      countResponse = await API_POSTS_BJ({
        method: 'get',
        directUrl: urlCount,
      });
    }

    let res = [];
    if (response.data.data) {
      res = Object.keys(response.data.data).map((key) => ({
        title: key,
        data: response.data.data[key],
      }));
    }

    return {
      data: res,
      type: payload.type,
      skip: payload.skipValue ?? undefined,
      count: countResponse ? countResponse.data.count : undefined,
    };
  },
);

export const getPostGlossary = createAsyncThunk(
  'section/getPostGlossary',
  async (slug) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: `/${slug}`,
      payload: {
        params: {
          filter: {
            post_type: 'bjglossary',
          },
        },
      },
    });
    return response.data;
  },
);
