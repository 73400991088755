import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import {
  bool,
  string,
  number,
  array,
  oneOfType,
  any,
  func,
  object,
} from 'prop-types';
import ReactPlayer from 'react-player';
import { A, P, H2 } from '@expo/html-elements';
import { useNavigation } from '@react-navigation/native';

import { screenWidth } from '~global-screens';
import { Spacer } from '@web-components';
import { capitalizeFirstAllLetterSentence } from '@web-utils';
import CdnImage from '../cdnImage/CdnImage';

const Content = {
  category: 'INSPIRATION',
  date: '23 Maret 2023',
  title:
    "Onycha Hadir dengan Wajah Baru Lewat Koleksi 'Fastening Classic x Modernity'",
  author: 'Emanuella Lintang',
  detail:
    'Onycha (baca: o-ni-ka), label fesyen lokal asal Bandung kini menampilkan wajah baru lewat koleksi bertajuk “Fastening Classic x Modernity” di gelaran trunk show pada Jumat, 7 Juli 2023 di ...',
};

const Config = require('../../../config/default.env').default;

const ArticleWrapper = (props) => {
  const {
    horizontal,
    containerStyle,
    spaceImageToText,
    metaHorizontal,
    metaFonts,
    titleFonts,
    authorFonts,
    data,
    onLayout,
    noImage,
    detail,
    detailFonts,
    isVideo,
    imageHeight,
    numberOfLinesTitle,
    alt,
    onHoverIn,
    onHoverOut,
    detailContainerStyle,
    imageStyle,
    customFlexImage,
    imageSize,
    onPress,
    post_type,
    slug,
  } = props;
  const styles = componentStyles(props);
  const navigation = useNavigation();
  const [articleData, setArticleData] = useState({
    category: Content.category,
    date: Content.date,
    title: Content.title,
    author: Content.author,
    imageSource: require('~assets/images/dummy/image-3.png'),
    detail: Content.detail,
  });

  useEffect(() => {
    if (data) {
      setArticleData({
        ...articleData,
        ...data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const FONT_400 = { fontWeight: 400 };

  const handlePressItem = useCallback(() => {
    switch (post_type) {
      case 'pid_quiz':
      case 'polling':
        navigation.push('QuizScreen', {
          id: slug,
        });
        break;
      case 'bjtv':
        navigation.push('ShortScreen', { slug });
        break;
      default:
        navigation.push('ArticleDetailScreen', {
          slug,
        });
        break;
    }
  }, [navigation, post_type, slug]);

  let href = '';
  switch (post_type) {
    case 'pid_quiz':
    case 'polling':
      href = 'quiz';
      break;
    case 'bjtv':
      href = 'beauty-short';
      break;
    default:
      href = 'article';
      break;
  }

  return (
    <Pressable
      accessibilityRole="link"
      style={StyleSheet.flatten([styles.container, containerStyle])}
      onLayout={onLayout}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      onPress={(event) => {
        event.preventDefault();
        handlePressItem();
        if (onPress) {
          onPress();
        }
      }}>
      {!noImage && !isVideo && (
        <Pressable
          style={StyleSheet.flatten([
            styles.imageContainer,
            { flex: props.horizontal ? customFlexImage : 'none' },
          ])}
          onPress={(event) => {
            event.preventDefault();
            handlePressItem();
            if (onPress) {
              onPress();
            }
          }}
          href={`/${href}/${slug}`}>
          <CdnImage
            source={articleData.imageSource}
            style={imageStyle ?? styles.articleImg}
            alt={alt}
            role="presentation"
            customImageSize={
              props.alt == 'highlight-image-content' ? imageSize * 2 : imageSize
            }
          />
        </Pressable>
      )}
      {!noImage && isVideo && (
        <ReactPlayer
          url={`https://www.youtube.com/embed/${articleData?.imageSource?.uri}?modestbranding=1`}
          height={imageHeight}
          width={'100%'}
          config={{
            youtube: {
              playerVars: { modestbranding: 1 },
            },
          }}
          light
          style={styles.playerStyle}
        />
      )}
      {!horizontal && <Spacer height={spaceImageToText} />}
      <View
        style={StyleSheet.flatten([
          styles.detailContainer,
          detailContainerStyle,
        ])}>
        <Text style={[metaFonts, styles.noMargin]}>
          <A href={`/${href}/${slug}`}>
            <View
              // eslint-disable-next-line react-native/no-inline-styles
              style={{
                ...(metaHorizontal && {
                  display: 'inline',
                }),
                flexDirection: 'column',
              }}>
              <Text>{articleData.category}</Text>
              {articleData.date && (
                <>
                  {metaHorizontal && <Text style={metaFonts}> | </Text>}
                  <Text style={[metaFonts, styles.noMargin]}>
                    {articleData.date}
                  </Text>
                </>
              )}
            </View>
          </A>
        </Text>

        <Pressable
          onPress={(event) => {
            event.preventDefault();
            handlePressItem();
            if (onPress) {
              onPress();
            }
          }}>
          <A href={`/${href}/${slug}`}>
            <H2
              role="heading"
              aria-level={2}
              numberOfLines={numberOfLinesTitle ?? 3}
              style={[titleFonts, styles.noMargin]}>
              {articleData.title}
            </H2>
          </A>
        </Pressable>
        {articleData.author && (
          <Text numberOfLines={1} style={authorFonts}>
            <Text style={[...authorFonts, FONT_400]}>by </Text>
            <A
              href={`${Config.SOCO_WEB_URL}/${articleData.authorUserName}`}
              target="_blank">
              {capitalizeFirstAllLetterSentence(articleData.author)}
            </A>
          </Text>
        )}
        {detail && (
          <P numberOfLines={4} style={[detailFonts, styles.noMargin]}>
            <p>{articleData.detail}</p>
          </P>
        )}
      </View>
    </Pressable>
  );
};

const componentStyles = StyleSheet.create((props) => ({
  container: {
    flexDirection: props.horizontal ? 'row' : 'column',
    columnGap: props.horizontal ? props.spaceImageToText : 0,
    maxWidth: props.maxWidth,
    marginRight: props.spaceRightItem,
    marginLeft: props.spaceLeftItem,
  },
  imageContainer: {
    flex: props.horizontal ? 1.5 : 'none',
  },
  articleImg: {
    height: props.imageHeight,
    width: '100%',
  },
  detailContainer: {
    rowGap: props.spaceBetweenText,
    flex: props.horizontal ? 3 : 'none',
    width: '100%',
  },
  metaContainer: {
    flexDirection: props.metaHorizontal ? 'row' : 'column',
    flexWrap: 'wrap',
  },
  playerStyle: {
    borderRadius: 10,
    overflow: 'hidden',
  },
  noMargin: { marginTop: 0, marginBottom: 0 },
}));

ArticleWrapper.defaultProps = {
  maxWidth: screenWidth * 0.25,
  imageHeight: 114,
  spaceLeftItem: 0,
  metaHorizontal: false,
  customFlexImage: 1.5,
  alt: '',
};

ArticleWrapper.propTypes = {
  horizontal: bool,
  metaHorizontal: bool,
  maxWidth: oneOfType([string, number]),
  imageHeight: oneOfType([string, number]),
  containerStyle: oneOfType([object, array]),
  spaceImageToText: number,
  spaceBetweenText: number,
  spaceRightItem: number,
  spaceLeftItem: number,
  metaFonts: any,
  titleFonts: any,
  authorFonts: any,
  detailFonts: any,
  data: object,
  onPress: func,
  onLayout: func,
  noImage: bool,
  detail: bool,
  isVideo: bool,
  imageSource: any,
  numberOfLinesTitle: number,
  alt: string,
  onHoverIn: func,
  onHoverOut: func,
  detailContainerStyle: oneOfType([object, array]),
  imageStyle: oneOfType([object, array]),
  customFlexImage: any,
  imageSize: oneOfType([number, string]),
  href: string,
  post_type: string,
  slug: string,
};

export default ArticleWrapper;
