/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { HTMLElementModel, HTMLContentModel } from 'react-native-render-html';
import PropTypes from 'prop-types';

import { CreateResponsiveStyle, maxWidthContainer } from '~global-screens';

// Add this outside of components to ensure single instance
let tiktokScriptLoaded = false;

const loadTiktokScript = () => {
  return new Promise((resolve) => {
    if (tiktokScriptLoaded) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://www.tiktok.com/embed.js';
    script.async = true;
    script.onload = () => {
      tiktokScriptLoaded = true;
      resolve();
    };
    document.body.appendChild(script);
  });
};

const customHTMLElementModels = {
  'tiktok-frame': HTMLElementModel.fromCustomModel({
    tagName: 'tiktok-frame',
    contentModel: HTMLContentModel.block,
  }),
};

const parseTiktokData = (i, tiktokLink) => {
  const url = tiktokLink;
  let parsed = '';
  if (url && url.length) {
    parsed = `<tiktok-frame id=${url} />`;
  }
  return parsed;
};

const parseHtmlData = (listData, htmlContent) => {
  let parsedContent = htmlContent;
  for (let i = 0; i < listData.length; i++) {
    parsedContent = parsedContent.replace(
      new RegExp(listData[i].replace(/[*+?^${}()|[\]\\]/g, '\\$&')),
      parseTiktokData(i, listData[i]),
    );
  }

  return parsedContent;
};

const getTiktokEmbedData = async (url) => {
  try {
    const response = await fetch(`https://www.tiktok.com/oembed?url=${url}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching TikTok embed data:', error);
    return null;
  }
};

const TiktokFrame = ({ tnode }) => {
  const [embedData, setEmbedData] = useState(null);
  const styles = componentStyles();

  useEffect(() => {
    const loadEmbedData = async () => {
      const data = await getTiktokEmbedData(tnode.id);
      if (data) {
        setEmbedData(data);
        await loadTiktokScript();
        if (window.TikTok) {
          window.TikTok.reloadEmbeds();
        }
      }
    };
    loadEmbedData();
  }, [tnode.id]);

  if (!embedData) {
    return null;
  }

  return (
    <View style={styles('containerWebView')}>
      <div
        dangerouslySetInnerHTML={{ __html: embedData.html }}
        style={{ width: '100%', minHeight: 600 }}
      />
      {embedData.thumbnail_url && (
        <img
          src={embedData.thumbnail_url}
          alt={embedData.title || 'TikTok video thumbnail'}
          style={{ display: 'none' }}
        />
      )}
    </View>
  );
};

const webStyles = {
  containerWebView: {
    width: '100%',
    maxWidth: maxWidthContainer,
    alignSelf: 'center',
    minHeight: 600,
    marginVertical: 16,
  },
};

const mobileStyles = {};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

const renderers = {
  'tiktok-frame': TiktokFrame,
};

TiktokFrame.propTypes = {
  tnode: PropTypes.shape({
    // treat id as the video url
    id: PropTypes.string,
  }),
};

export default { renderers, customHTMLElementModels, parseHtmlData };
