/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { HTMLElementModel, HTMLContentModel } from 'react-native-render-html';
import PropTypes from 'prop-types';

import { CreateResponsiveStyle } from '~global-screens';

const customHTMLElementModels = {
  'twitter-frame': HTMLElementModel.fromCustomModel({
    tagName: 'twitter-frame',
    contentModel: HTMLContentModel.block,
  }),
};

const replaceXWithTwitter = (url) => {
  if (url.startsWith('https://x.com/')) {
    return url.replace('https://x.com/', 'https://twitter.com/');
  }
  return url;
};

const parseTwitterData = (i, twtLink) => {
  const url = replaceXWithTwitter(twtLink);

  let parsed = '';
  if (url && url.length) {
    parsed = `<twitter-frame id=${url} />`;
  }
  return parsed;
};

const parseHtmlData = (listData, htmlContent) => {
  let parsedContent = htmlContent;

  for (let i = 0; i < listData.length; i++) {
    const processedUrl = replaceXWithTwitter(listData[i]);
    parsedContent = parsedContent.replace(
      new RegExp(listData[i].replace(/[*+?^${}()|[\]\\]/g, '\\$&')),
      parseTwitterData(i, processedUrl),
    );
  }

  return parsedContent;
};

let twitterScriptLoaded = false;

const loadTwitterScript = () => {
  return new Promise((resolve) => {
    if (twitterScriptLoaded) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    script.onload = () => {
      twitterScriptLoaded = true;
      resolve();
    };
    document.body.appendChild(script);
  });
};

const TwitterFrame = ({ tnode }) => {
  const styles = componentStyles();
  const htmlStr = `<blockquote class="twitter-tweet" data-lang="id"><a href="${tnode.id}"></a></blockquote>`;

  useEffect(() => {
    const loadWidget = async () => {
      await loadTwitterScript();
      if (window.twttr) {
        window.twttr.widgets.load();
      }
    };
    loadWidget();
  }, [tnode.id]);

  return (
    <View style={styles('containerWebView')}>
      <div
        dangerouslySetInnerHTML={{ __html: htmlStr }}
        style={{ width: '100%', minHeight: 360 }}
      />
    </View>
  );
};

const webStyles = {
  containerWebView: {
    width: '100%',
    maxWidth: 550,
    alignSelf: 'center',
    minHeight: 360,
    marginVertical: 16,
  },
};

const mobileStyles = {};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

const renderers = {
  'twitter-frame': TwitterFrame,
};

TwitterFrame.propTypes = {
  tnode: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default { renderers, customHTMLElementModels, parseHtmlData };
