import axios from 'axios';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { getStorage, storageName, removeStorage } from '@web-utils';
export const SOC = {
  android: 'soco-android',
  ios: 'soco-ios',
  web: 'soco-web-desktop',
};
export default function interceptorsSetup() {
  axios.interceptors.request.use(
    async (config) => {
      // endpoint
      const platform = SOC[Platform.OS];

      // v3 product requires the platform to be sociolla-web-mobile in order to get the correct price and discount information
      if (config.url.includes('/v3/products')) {
        config.headers['SOC-PLATFORM'] = 'sociolla-web-mobile';
        config.headers['SOC-SOURCE'] = null;
      } else {
        config.headers['SOC-PLATFORM'] = platform;
        config.headers['SOC-SOURCE'] = 'bj';
      }

      config.headers['Content-Type'] = 'application/json';
      config.headers['Accept-Language'] = 'en';
      config.withCredentials = true;

      const token = JSON.parse(
        await AsyncStorage.getItem(storageName.userToken),
      );

      if (
        token &&
        !config.url.includes('amazonaws.com') &&
        config.url.indexOf('/login') == -1
      ) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      try {
        let d = new Date().getTime();
        let newGuid = '';

        if (!getStorage(storageName.guid)) {
          if (
            typeof performance !== 'undefined' &&
            typeof performance.now === 'function'
          ) {
            d += performance.now();
          }
          newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
              const r = (d + Math.random() * 16) % 16 | 0;
              d = Math.floor(d / 16);
              return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
            },
          );
          localStorage.setItem(storageName.guid, newGuid);
        } else {
          newGuid = getStorage(storageName.guid);
        }

        config.headers['session_id'] = newGuid;
      } catch (e) {
        console.log(e);
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status == 401) {
        removeStorage(storageName.userToken);
        removeStorage(storageName.userData);
        window.dataLayer.push({
          event: 'loggedOut',
        });
        return (window.location.href = '/');
      }
      if (error.code == 'ERR_CANCELED') {
        error.is_cancelled = true;
      }

      return Promise.reject(error);
    },
  );
}
