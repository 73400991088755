import React, { useCallback, useState, useEffect } from 'react';
import {
  View,
  Image,
  Text,
  TextInput,
  Pressable,
  Linking,
  ActivityIndicator,
} from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CheckBox } from 'react-native-web';
import Toast from 'react-native-toast-message';

import { CreateResponsiveStyle, screenWidth, isWeb } from '~global-screens';
import { Colors, Fonts, hs, vs } from '@web-styles';
import { Spacer } from '@web-components';
import { toggleModalStatus } from '~store/app/slice';
import {
  selectFetchStatus,
  resetError,
  selectAuthenticated,
} from '~store/auth/authSlice';
import { postLogin } from '~store/auth/authThunk';
import { useKeyboardListener } from '~global-hooks';

import BaseModal from './BaseModal';
const Config = require('../../../../config/default.env').default;

const LoginModal = ({ visible }) => {
  const dispatch = useDispatch();
  const styles = componentStyles();

  const isLoading = useSelector(selectFetchStatus('postLoginFetch'));
  const loginFetchError = useSelector(selectFetchStatus('postLoginError'));
  const isAuthenticated = useSelector(selectAuthenticated);

  const [staySignIn, setStaySignIn] = useState(false);
  const [formLogin, setFormLogin] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (visible) {
      dispatch(resetError());
      setFormLogin({
        email: '',
        password: '',
      });
    }
  }, [dispatch, visible]);

  const toggle = useCallback(() => {
    dispatch(toggleModalStatus('loginModal', false));
  }, [dispatch]);

  const handleTypeForm = useCallback(
    (name, value) => {
      setFormLogin({
        ...formLogin,
        [name]: value,
      });
    },
    [formLogin],
  );

  const handleSubmit = useCallback(() => {
    dispatch(postLogin(formLogin));
  }, [dispatch, formLogin]);

  const handleSubmitKeyboard = useCallback(
    (event) => {
      if (event.key === 'Enter' && formLogin.email && formLogin.password) {
        handleSubmit();
      }
    },
    [formLogin, handleSubmit],
  );

  useKeyboardListener(handleSubmitKeyboard, 'keydown');

  useEffect(() => {
    if (isAuthenticated && visible && !isLoading) {
      toggle();
    }
  }, [isAuthenticated, isLoading, toggle, visible]);

  useEffect(() => {
    if (loginFetchError && !isAuthenticated) {
      Toast.show({
        type: 'error',
        text1: loginFetchError,
        onHide: () => dispatch(resetError()),
        onPress: () => dispatch(resetError()),
      });
    }
  }, [dispatch, isAuthenticated, loginFetchError]);

  return (
    <BaseModal visible={visible} toggle={toggle}>
      <View style={styles('container')}>
        <Pressable onPress={toggle} style={styles('closeBtn')}>
          <AntDesign name="closecircle" size={24} color="black" />
        </Pressable>
        <Image
          source={require('~assets/images/logo-soco.svg')}
          style={styles('logoSoco')}
        />
        <Spacer height={15} />
        <Text
          style={[Fonts('addAuthorHighlight'), styles('addAuthorHighlight')]}>
          Masuk dengan akun SOCO
        </Text>
        <Spacer height={30} />
        <View>
          <Text style={[Fonts('formTitle'), { color: Colors.SPANISH_GRAY }]}>
            Username / Email
          </Text>
          <TextInput
            value={formLogin.email}
            onChangeText={(text) => handleTypeForm('email', text)}
            style={styles('textInputStyle')}
            id="email"
            accessibilityLabel="email"
          />
          <Spacer height={12} />
          <Text style={[Fonts('formTitle'), { color: Colors.SPANISH_GRAY }]}>
            Password
          </Text>
          <TextInput
            value={formLogin.password}
            onChangeText={(text) => handleTypeForm('password', text)}
            secureTextEntry
            style={styles('textInputStyle')}
            id="password"
            accessibilityLabel="password"
          />
        </View>
        <Spacer height={34} />
        <View style={styles('optionContainer')}>
          <View style={styles('stayContainer')}>
            <CheckBox
              value={staySignIn}
              onChange={() => {
                setStaySignIn((prev) => !prev);
              }}
            />
            <Text style={Fonts('formTitle')}>Stay sign in</Text>
          </View>
          <Pressable
            onPress={() => {
              Linking.openURL(`${Config.SOCO_WEB_URL}/forgot`);
            }}>
            <Text style={[Fonts('formTitle'), { color: Colors.AMARANTH_PINK }]}>
              Lupa password
            </Text>
          </Pressable>
        </View>
        <Spacer height={12} />
        <Pressable
          onPress={() => handleSubmit()}
          disabled={!formLogin.email || !formLogin.password}
          style={styles('submitBtn')}>
          {isLoading ? (
            <ActivityIndicator color={Colors.WHITE} />
          ) : (
            <Text style={[Fonts('formSubmitTxt'), { color: Colors.WHITE }]}>
              MASUK
            </Text>
          )}
        </Pressable>
        <Spacer height={130} />
        <View style={styles('divider')} />
        <Spacer height={23} />
        <Text style={[Fonts('addAuthorHighlight'), styles('centerAlignment')]}>
          Belum memiliki akun? Daftar sekarang, yuk!
        </Text>
        <Spacer height={13} />
        <Pressable
          onPress={() => {
            const acquisitionSource = isWeb
              ? 'bj-web-desktop'
              : 'bj-web-mobile';
            Linking.openURL(
              `${Config.SOCO_WEB_URL}/register?acquisition_source=${acquisitionSource}`,
            );
          }}
          style={[styles('submitBtn'), styles('signUpBtnWrapper')]}>
          <Text style={Fonts('formSubmitTxt')}>DAFTAR</Text>
        </Pressable>
      </View>
    </BaseModal>
  );
};
const webStyles = {
  container: {
    backgroundColor: Colors.WHITE,
    padding: 34,
    width: 483,
    borderRadius: 4,
  },
  logoSoco: {
    width: 145,
    height: 33,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  textInputStyle: {
    borderWidth: 1,
    borderColor: Colors.SPANISH_GRAY,
    height: 40,
    padding: 5,
    paddingHorizontal: 10,
  },
  optionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  stayContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 7,
  },
  checkBox: {
    borderWidth: 1,
    width: 14,
    height: 14,
    borderColor: Colors.PLATINUM,
  },
  submitBtn: {
    backgroundColor: Colors.BLACK,
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: Colors.PLATINUM,
  },
  signUpBtnWrapper: { backgroundColor: Colors.WHITE, borderWidth: 1 },
  centerAlignment: { textAlign: 'center' },
  addAuthorHighlight: { fontSize: 14, textAlign: 'center' },
};

const mobileStyles = {
  logoSoco: {
    width: hs(150),
    height: vs(33),
  },
  container: {
    maxWidth: 380,
    width: screenWidth * 0.95,
  },
};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

LoginModal.propTypes = {
  visible: PropTypes.bool,
};

export default LoginModal;
