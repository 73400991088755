import React, { useState, useEffect, useCallback } from 'react';
import { Text, View, StyleSheet, Pressable } from 'react-native';
import {
  object,
  oneOfType,
  string,
  number,
  bool,
  array,
  func,
} from 'prop-types';
import { A, P, H2 } from '@expo/html-elements';
import { useNavigation, useNavigationState } from '@react-navigation/native';

import { CreateResponsiveStyle, isWeb, screenWidth } from '~global-screens';
import { Fonts, Colors } from '@web-styles';
import { capitalizeFirstAllLetterSentence } from '@web-utils';
import CdnImage from '../cdnImage/CdnImage';
const Config = require('../../../config/default.env').default;
const startCase = require('lodash.startcase');

const FONT_400 = { fontWeight: 400 };
const FONT_500 = { fontWeight: 500 };
const FONT_600 = { fontWeight: 600 };

const ArticleCard = ({
  data,
  containerWidth,
  imageToTextWidth,
  textToTextWidth,
  fullImage,
  noBorder,
  metaHorizontal,
  metaFonts,
  titleFonts,
  authorFonts,
  detail,
  detailFonts,
  numberOfLinesTitle,
  horizontal,
  imageWidth,
  customPadding,
  onLayout,
  onHoverIn,
  onHoverOut,
  alt,
  imageSize,
  fixContainerHeight,
  noImage,
  onPress,
  post_type,
  slug,
  sidebar,
}) => {
  const styles = componentStyles();
  const navigation = useNavigation();
  const [articleData, setArticleData] = useState({
    category: 'INSPIRATION',
    date: '23 Maret 2023',
    title:
      "Onycha Hadir dengan Wajah Baru Lewat Koleksi 'Fastening Classic x Modernity'",
    author: 'Emanuella Lintang',
    imageSource: require('~assets/images/dummy/image-3.png'),
    detail:
      'Onycha (baca: o-ni-ka), label fesyen lokal asal Bandung kini menampilkan wajah baru lewat koleksi bertajuk “Fastening Classic x Modernity” di gelaran trunk show pada Jumat, 7 Juli 2023 di ...',
  });

  useEffect(() => {
    if (data) {
      setArticleData({
        ...articleData,
        ...data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const CurrentScreen = () => {
    const routeName = useNavigationState((state) => {
      const route = state.routes[state.index];
      return route.name;
    });
    return routeName;
  };
  const routeName = CurrentScreen();

  const IMAGE_STYLE = {
    width: horizontal ? imageWidth : '100%',
    aspectRatio: 3 / 4,
  };

  const META_STYLE = {
    flexDirection: metaHorizontal ? 'row' : 'column',
    flexWrap: 'wrap',
  };
  const CATEGORY_LINKS = {
    'whats-good': ['news-scoop', 'now-trending', 'who-to-watch'],
    'whats-new': [
      'editors-review',
      'guides',
      'hot-inspo',
      'top-picks',
      'the-a-list',
    ],
    'whats-fun': ['quizzes-poll', 'url-finds'],
  };
  const FLATTEN_CATEGORY = Object.values(CATEGORY_LINKS).reduce(
    (acc, array) => acc.concat(array),
    [],
  );
  const findParentCategory = (value) => {
    return Object.entries(CATEGORY_LINKS).find(([key, array]) =>
      array.includes(value),
    )?.[0];
  };
  const handleGotoSearchScreen = useCallback(
    (id) => {
      const keyword = id.toLowerCase().replaceAll("'", '');
      const currentCategory = startCase(keyword).replace(/\s+/g, '');

      if (routeName !== 'HomeScreen' && routeName.includes(currentCategory)) {
        return;
      }

      const parentCategory = FLATTEN_CATEGORY.includes(keyword)
        ? findParentCategory(keyword)
        : null;

      const isKeywordMatching = (category) =>
        keyword === category || parentCategory === category;

      if (isKeywordMatching('whats-good')) {
        const url = parentCategory ? `/whats-good/${keyword}` : null;
        url ? (window.location.href = url) : navigation.push('WhatsGoodScreen');
      } else if (isKeywordMatching('whats-new')) {
        const url = parentCategory ? `/whats-new/${keyword}` : null;
        url ? (window.location.href = url) : navigation.push('WhatsNewScreen');
      } else if (isKeywordMatching('whats-fun')) {
        const url = parentCategory ? `/whats-fun/${keyword}` : null;
        url ? (window.location.href = url) : navigation.push('WhatsFunScreen');
      } else {
        navigation.push('SearchScreen', {
          type: 'search',
          keyword,
          route: 'search',
        });
      }
    },
    [navigation, routeName],
  );

  const handlePressItem = useCallback(() => {
    switch (post_type) {
      case 'pid_quiz':
      case 'polling':
        navigation.push('QuizScreen', {
          id: slug,
        });
        break;
      case 'bjtv':
        navigation.push('ShortScreen', { slug });
        break;
      default:
        navigation.push('ArticleDetailScreen', {
          slug,
        });
        break;
    }
  }, [navigation, post_type, slug]);

  let href = '';
  switch (post_type) {
    case 'pid_quiz':
    case 'polling':
      href = 'quiz';
      break;
    case 'bjtv':
      href = 'beauty-short';
      break;
    default:
      href = 'article';
      break;
  }

  return (
    <Pressable
      target="_blank"
      accessibilityRole="link"
      onLayout={onLayout}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      onPress={(event) => {
        event.preventDefault();
        handlePressItem();
        if (onPress) {
          onPress();
        }
      }}
      style={StyleSheet.flatten([
        styles('container'),
        {
          width: containerWidth,
          rowGap: horizontal ? 0 : imageToTextWidth,
          columnGap: horizontal ? imageToTextWidth : 0,
          flexDirection: horizontal ? 'row' : 'none',
          ...(fullImage ? { padding: 0 } : { padding: isWeb ? 16 : 12 }),
          ...(noBorder && { borderWidth: 0 }),
          ...(customPadding && { padding: customPadding }),
          ...(!fixContainerHeight && { height: '100%' }),
        },
      ])}>
      {!noImage && (
        <Pressable
          style={StyleSheet.flatten([
            styles('imageContainer', {
              flex: horizontal ? 1 : 'none',
            }),
          ])}
          onPress={(event) => {
            event.preventDefault();
            handlePressItem();
            if (onPress) {
              onPress();
            }
          }}
          href={`/${href}/${slug}`}>
          <CdnImage
            source={articleData.imageSource}
            style={IMAGE_STYLE}
            alt={alt}
            customImageSize={imageSize ? imageSize : imageWidth * 2.5}
            role="presentation"
          />
        </Pressable>
      )}
      <View
        style={StyleSheet.flatten([
          styles('detailContainer'),
          {
            rowGap: textToTextWidth,
            flex: horizontal ? 2.5 : 1,
            justifyContent: horizontal || detail ? 'none' : 'space-between',
            ...(fullImage && { padding: isWeb ? 16 : 12 }),
          },
        ])}>
        <View style={{ rowGap: textToTextWidth }}>
          <Pressable
            onPress={(event) => {
              event.preventDefault();
              handleGotoSearchScreen(articleData.categorySlug);
            }}
            style={[META_STYLE, styles('metaContainer')]}>
            <Text style={[...metaFonts, FONT_500, styles('noMargin')]}>
              {articleData.category}
            </Text>
            {articleData.date && (
              <Text
                style={[
                  ...metaFonts,
                  FONT_400,
                  styles('noMargin'),
                  styles('dateText'),
                ]}>
                {articleData.date}
              </Text>
            )}
          </Pressable>
          <Pressable
            onPress={(event) => {
              event.preventDefault();
              handlePressItem();
              if (onPress) {
                onPress();
              }
            }}
            href={`/${href}/${slug}`}>
            <H2
              role="heading"
              aria-level={1}
              style={[
                ...titleFonts,
                FONT_600,
                styles('noMargin'),
                {
                  ...(!isWeb &&
                    sidebar && {
                      maxWidth: screenWidth - 70,
                    }),
                },
              ]}
              numberOfLines={numberOfLinesTitle}>
              {articleData.title}
            </H2>
          </Pressable>
        </View>
        {articleData.author && (
          <Text
            numberOfLines={1}
            style={[...authorFonts, FONT_600, styles('noMargin')]}>
            <Text style={[...authorFonts, FONT_400]}>by </Text>
            <A
              href={`${Config.SOCO_WEB_URL}/${articleData.authorUserName}`}
              target="_blank">
              {capitalizeFirstAllLetterSentence(articleData.author)}
            </A>
          </Text>
        )}
        {detail && (
          <P numberOfLines={4} style={[...detailFonts, FONT_500]}>
            <p>{articleData.detail}</p>
          </P>
        )}
      </View>
    </Pressable>
  );
};

const webStyles = {
  container: {
    borderWidth: 1,
    borderRadius: 3,
    backgroundColor: Colors.WHITE,
  },
  metaContainer: {
    flexDirection: 'column',
  },
  imageContainer: {
    paddingBottom: 0,
  },
  detailContainer: {
    paddingTop: 0,
    padding: 0,
  },
  dateText: {
    marginTop: 2,
  },
  noMargin: { marginTop: 0, marginBottom: 0 },
};

const mobileStyles = {};

ArticleCard.defaultProps = {
  containerWidth: 267,
  imageToTextWidth: 16,
  imageSource: require('~assets/images/dummy/image-3.png'),
  textToTextWidth: 10,
  fullImage: false,
  noBorder: false,
  metaHorizontal: false,
  metaFonts: [Fonts('categoryMeta'), { letterSpacing: 0.24 }],
  titleFonts: [Fonts('megaMenuArticleTitle')],
  authorFonts: [Fonts('megaMenuArticleAuthor')],
  detail: false,
  detailFonts: [Fonts('dateHeader')],
  numberOfLinesTitle: 3,
};

ArticleCard.propTypes = {
  data: object,
  containerWidth: oneOfType([number, string]),
  imageToTextWidth: oneOfType([number, string]),
  textToTextWidth: oneOfType([number, string]),
  imageWidth: oneOfType([number, string]),
  customPadding: oneOfType([number, string]),
  fullImage: bool,
  noBorder: bool,
  metaHorizontal: bool,
  metaFonts: oneOfType([object, array]),
  titleFonts: oneOfType([object, array]),
  authorFonts: oneOfType([object, array]),
  detail: bool,
  detailFonts: oneOfType([object, array]),
  numberOfLinesTitle: number,
  horizontal: bool,
  onPress: func,
  onLayout: func,
  onHoverIn: func,
  onHoverOut: func,
  alt: string,
  imageSize: oneOfType([number, string]),
  fixContainerHeight: bool,
  noImage: bool,
  href: string,
  post_type: string,
  slug: string,
  sidebar: bool,
};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);
export default ArticleCard;
